import {provideHttpClient, withInterceptors} from '@angular/common/http'
import {ApplicationConfig, inject, provideAppInitializer, provideZoneChangeDetection} from '@angular/core'
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async'
import {provideRouter, withHashLocation, withInMemoryScrolling} from '@angular/router'
import {routes} from './app.routes'
import {authInterceptor} from './application/auth.interceptor'
import {responseInterceptor} from './application/response.interceptor'
import {ConfigService} from './services/config.service'
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field'

export const appConfig: ApplicationConfig = {
  providers: [
    provideAppInitializer(() => {
      const initializerFn = ((s: ConfigService) => () => s.bootstrap())(inject(ConfigService))
      return initializerFn()
    }),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {
        subscriptSizing: 'dynamic',
        floatLabel: 'always',
        appearance: 'outline'
      }
    },
    provideZoneChangeDetection({eventCoalescing: true}),
    provideRouter(routes, withHashLocation(), withInMemoryScrolling({anchorScrolling: 'enabled'})),
    provideAnimationsAsync(),
    provideHttpClient(
      withInterceptors([
        authInterceptor,
        responseInterceptor
      ])
    )
  ]
}